<template>
  <div>
    <form @submit.prevent="onsubmit">
      <va-input
        class="mb-3"
        v-model="email"
        type="email"
        :label="$t('auth.email')"
        :error="!!emailErrors.length"
        :error-messages="emailErrors"
      />

      <va-input
        class="mb-3"
        v-model="password"
        type="password"
        :label="$t('auth.password')"
        :error="!!passwordErrors.length"
        :error-messages="passwordErrors"
      />

      <div
        class="auth-layout__options d-flex align--center justify--space-between"
      >
        <va-checkbox
          v-model="keepLoggedIn"
          class="mb-0"
          :label="$t('auth.keep_logged_in')"
        />
        <router-link class="ml-1 link" :to="{ name: 'recover-password' }">{{
          $t("auth.recover_password")
        }}</router-link>
      </div>

      <div class="d-flex justify--center mt-3">
        <va-button @click="onsubmit" class="my-0">{{
          $t("auth.login")
        }}</va-button>
      </div>
    </form>
  </div>

  <va-modal
    v-model="showModal"
    size="small"
    title="ATENÇÃO"
    :message="msgModal"
    okText="OK"
    cancelClass="none"
  />
</template>

<script>
import conserv from "@/api/conserv";

export default {
  name: "login",
  mixins: [conserv],
  data() {
    return {
      showModal: false,
      msgModal: "",
      email: "",
      password: "",
      keepLoggedIn: false,
      emailErrors: [],
      passwordErrors: [],
    };
  },
  computed: {
    formReady() {
      let bRes = true;
      bRes = !this.emailErrors.length && !this.passwordErrors.length;
      if (!bRes) {
        this.msgModal = "Informe o e-mail e a senha";
        bRes = false;
        return;
      }

      if (!this.validateEmail(this.email)) {
        this.msgModal = "E-mail inválido!";
        bRes = false;
        return;
      }

      return bRes;
    },
  },
  methods: {
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    async onsubmit() {
      this.msgModal = "";
      this.emailErrors = this.email ? [] : ["Email é obrigatório"];
      this.passwordErrors = this.password ? [] : ["Senha é obrigatória"];
      if (!this.formReady) {
        this.showModal = true;
        return;
      }

      let dadosPost = {
        Username: this.email,
        Password: this.password,
      };
      let json = await this.connServ("Login/FazLogin", "post", dadosPost);

      if (json.bstatus) {
        localStorage.setItem("userDados", JSON.stringify(json.dados));
        this.$store.commit("changeUserLogado", true);
        this.$store.commit("changeUserDados", JSON.stringify(json.dados));
        this.$router.push({ name: "dashboard" });
      } else {
        if (json.msg.response.status == "401") {
          this.msgModal = "Usuário não encontrado!";
        } else {
          this.msgModal = "ERRO: " + json.msg.response.data;
        }
        this.showModal = true;
      }
    },
  },
  mounted() {
    //fazendo logof..
    this.$store.commit("changeUserLogado", false);
    this.$store.commit("changeUserDados", {});
    localStorage.setItem("userDados", "{}");
  },
};
</script>
